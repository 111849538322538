import React from 'react'


//rfce + enter
const Footer = () => {  

    return (        
        <div id="footer" className="footer">
            <div className="footer-container">
                <p>Auckland Kendo Club © 2020. All Rights Reserved.</p>
            </div>
        </div>  
    )
}

export default Footer